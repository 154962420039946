<template>
  <div class="container-fluid p-0" v-if="isDesktopSize">
    <div class="row flex-nowrap">
      <div class="col-auto col-md-6 col-xl-4 px-sm-2 px-0 top-round-container">
        <div
          class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white desktop_view"
        >
          <center style="align-self: center !important">
            <p class="text-center text_info">
              {{
                this.$i18n.locale === "en"
                  ? "Enter Your OTP"
                  : "أدخل الرمز السري"
              }}
            </p>
            <div id="otp" class="mt-2">
              <input
                v-model="pin1"
                type="text"
                v-on:keyup="keyMonitor"
                maxlength="1"
              />
              <input
                v-model="pin2"
                type="text"
                v-on:keyup="keyMonitor"
                maxlength="1"
              />
              <input
                v-model="pin3"
                type="text"
                v-on:keyup="keyMonitor"
                maxlength="1"
              />
              <input
                v-model="pin4"
                type="text"
                v-on:keyup="keyMonitor"
                maxlength="1"
              />
              <input
                v-if="isZain"
                v-model="pin5"
                type="text"
                v-on:keyup="keyMonitor"
                maxlength="1"
              />
            </div>

            <div class="mt-4 butn">
              <button
                v-if="this.$i18n.locale == 'en'"
                :class="{ game_btn: true, activeBtn: isChanged }"
                :disabled="btnDisabled"
                id="sub_btn"
                @click="isZain ? verifyZain() : verify()"
              >
                {{ btnDisabled ? "Loading ..." : "Subscribe" }}
              </button>

              <button
                v-else
                :class="{ game_btn: true, activeBtn: isChanged }"
                :disabled="btnDisabled"
                id="sub_btn"
                @click="isZain ? verifyZain() : verify()"
              >
                {{ btnDisabled ? "تحميل..." : "اشترك" }}
              </button>
            </div>
          </center>
        </div>
      </div>
      <div class="col py-3">
        <!-- <b-img  fluid style="height:300px "  center src="../../assets/images/logo/logo.png"  alt="Center image"></b-img> -->
      </div>
    </div>
  </div>

  <div v-else>
    <div class="box">
      <div class="new fixed-bottom m-auto">
        <p class="text-center text_info">
          {{
            this.$i18n.locale === "en" ? "Enter Your OTP" : "أدخل الرمز السري"
          }}
        </p>
        <div id="otp" class="mt-2">
          <input
            v-model="pin1"
            type="text"
            v-on:keyup="keyMonitor"
            maxlength="1"
          />
          <input
            v-model="pin2"
            type="text"
            v-on:keyup="keyMonitor"
            maxlength="1"
          />
          <input
            v-model="pin3"
            type="text"
            v-on:keyup="keyMonitor"
            maxlength="1"
          />
          <input
            v-model="pin4"
            type="text"
            v-on:keyup="keyMonitor"
            maxlength="1"
          />
          <input
            v-if="isZain"
            v-model="pin5"
            type="text"
            v-on:keyup="keyMonitor"
            maxlength="1"
          />
        </div>

        <div class="mt-4 butn">
          <button
            v-if="this.$i18n.locale == 'en'"
            :class="{ game_btn: true, activeBtn: isChanged }"
            :disabled="btnDisabled"
            id="sub_btn"
            @click="isZain ? verifyZain() : verify()"
          >
            {{ btnDisabled ? "Loading ..." : "Subscribe" }}
          </button>

          <button
            v-else
            :class="{ game_btn: true, activeBtn: isChanged }"
            :disabled="btnDisabled"
            id="sub_btn"
            @click="isZain ? verifyZain() : verify()"
          >
            {{ btnDisabled ? "تحميل..." : "اشترك" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/api"
import AlertServie from "../../services/errors"
import Vue from "vue"
export default {
  data() {
    return {
      // prefixPHone: '+96475',
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
      pin5: "",
      phone: "+96475",
      isChanged: false,
      btnDisabled: false,
      isDesktopSize: false,
    }
  },
  computed: {
    isZain() {
      return this.$store.state.phoneNumber.startsWith("96478")
    },
  },
  methods: {
    checkScreenSize() {
      // Update isDesktopSize based on screen width
      this.isDesktopSize = window.innerWidth >= 768 // Adjust the breakpoint as needed
    },

    valueChanged(Val) {
      this.isChanged = true
    },
    keyMonitor(event) {
      if (event.key === "Backspace" || event.key === "Delete") {
        event.target.previousElementSibling.focus()
      } else {
        this.isChanged = true
        event.target.nextElementSibling.focus()
      }
    },
    async verify() {
      this.btnDisabled = true
      const otp = this.pin1 + this.pin2 + this.pin3 + this.pin4
      const phoneNumber = this.$store.state.phoneNumber
      const ts = this.$store.state.ts
      const ti = this.$store.state.ti
      const response = await ApiService.verify(phoneNumber, otp, ts, ti)
      const self = this

      if (response && response.status === 200) {
        if (response.data.data !== "") {
          localStorage.setItem("MyReligion", response.data.access_token)
          ApiService.headers = {
            Authorization: "Bearer " + response.data.access_token,
            lang: localStorage.getItem("lang"),
          }
          self.$router.push("/")
        } else {
          AlertServie.info(response.data.msg)

          if (response.status === 200) {
            this.checkUserStatusInterval = setInterval(async function () {
              const response = await ApiService.checkUserStatus(phoneNumber)
              if (response && response.status === 200) {
                if (response.data.status === 2 || response.data.status === 1) {
                  localStorage.setItem("MyReligion", response.data.access_token)
                  clearInterval(self.checkUserStatusInterval)
                  ApiService.headers = {
                    Authorization: "Bearer " + response.data.access_token,
                    lang: localStorage.getItem("lang"),
                  }
                  self.$router.push("/")
                  Vue.$forceUpdate()
                } else {
                  if (response.data.status === 3) {
                    clearInterval(self.checkUserStatusInterval)
                  }
                  AlertServie.info(response.data.msg)
                  this.disabled = false
                  this.btnDisabled = false
                }
              } else {
                clearInterval(self.checkUserStatusInterval)
                AlertServie.info(response.data.msg)
                this.btnDisabled = false
                this.disabled = false
              }
            }, 5000)
          }
        }
      } else {
        clearInterval(self.checkUserStatusInterval)
        this.disabled = false
        this.btnDisabled = false
        AlertServie.errorMessage(response.data.msg)
      }
    },

    // verify pin for zain
    async verifyZain() {
      this.btnDisabled = true
      try {
        const otp = this.pin1 + this.pin2 + this.pin3 + this.pin4 + this.pin5
        const res = await ApiService.verifyZain(
          this.$store.state.phoneNumber,
          localStorage.getItem("referenceId"),
          otp
        )
        if (res && res.status === 200) {
          // for dummy and subscribed user, go to content
          if (res.data?.code === 123 || res.data?.code === 22) {
            ApiService.headers = {
              Authorization: "Bearer " + res.data.access_token,
              lang: localStorage.getItem("lang"),
            }
            localStorage.setItem("MyReligion", res.data?.data?.access_token)
            this.$router.push("/")
          } else if (res.data?.code === 0) {
            this.$router.push("/thank-you")
          }
        }
      } catch (error) {
        this.disabled = false
        this.btnDisabled = false
        AlertServie.errorMessage(error.response?.data.message)
      } finally {
        this.disabled = false
        this.btnDisabled = false
      }
    },
  },
  beforeDestroy() {
    // Clean up by removing the window resize event listener
    window.removeEventListener("resize", this.checkScreenSize)
  },
  mounted() {
    // Check screen size on component mount and update isDesktopSize
    this.checkScreenSize()
    // Listen for window resize events to update isDesktopSize
    window.addEventListener("resize", this.checkScreenSize)
  },
  watch: {
    btnDisabled(val) {
      if (val) {
        document.getElementById("sub_btn").classList.add("activeBtn")
        document.getElementById("sub_btn").classList.remove("notActiveBtn")
      } else {
        document.getElementById("sub_btn").classList.remove("activeBtn")
        document.getElementById("sub_btn").classList.add("notActiveBtn")
      }
    },
  },
}
</script>

<style>
.top-round-container {
  padding: 60px !important;
  background-color: #1c2e2f;
  border-top-right-radius: 6rem !important;
  border-bottom-right-radius: 6rem !important;
}

.new {
  padding: 60px !important;
  width: 100%;
  height: fit-content;
  background-color: #1c2e2f;
  margin: 20px;
  border-top-left-radius: 6rem !important;
  border-top-right-radius: 6rem !important;
}

.input-with-image {
  position: relative;
}
.input-with-image input[type="text"] {
  background: url("https://images.unsplash.com/photo-1528360873366-55a2ad7dc282?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80")
    no-repeat center;
  background-size: cover;
  padding: 10px 40px 10px 15px;
  border: none;
  border-radius: 5px;
}
.input-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 1;
  outline: none;
}

.input-btn {
  outline: none !important;
  /* padding: 30px !important; */
  color: #ffffff;
}

.input-btn::placeholder {
  color: #ffffff;
}

img {
  width: 348.75px;
  height: 181.16px;
}

@media (max-width: 768px) {
  img {
    width: 200px;
    height: 100px;
  }
}
</style>
